import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SEO from '../../components/seo';
import Utils from '../../utils';
import ManualVM from '../../components/instructor/ManualVM';

const VMPage = () => {
  const state = useSelector(({ user }) => {
    return { user };
  });

  useEffect(() => {
    Utils.redirect(state.user.role);
  }, []);

  return (
    <>
      <SEO title="VM | SG Code Campus Online Platform App" />
      <ManualVM />
    </>
  );
};

export default VMPage;
