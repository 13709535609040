import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

import defaultSettings from '../VNC/defaultSettings.json';
import $ from '../../styles/global';
import Button from '../Button';

const LoadableVNC = loadable(() => import('../VNC'));

const VMContainer = styled.div`
  height: calc(100% - 40px - ${$.layout().padding3 * 2}px);
  width: 100%;
  background-color: ${$.color.gray1};
  position: relative;

  &.fullscreen {
    position: initial;
  }
`;

const Toolbar = styled.div`
  padding: ${$.layout().padding3}px 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 40px;

  > * {
    margin-right: ${$.layout().margin1}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const TextInput = styled.input.attrs({ type: 'text' })`
  border: 1px solid ${$.color.gray1};
  border-radius: 5px;
  padding: 6px ${$.layout().padding1}px;
  outline: none;
  color: ${$.color.blue4};
  font-size: 17px;
  line-height: 17px;
  margin-bottom: ${$.layout().margin1}px;
  ::placeholder {
    color: ${$.color.gray3};
  }
`;

const ConnectButton = styled(Button)``;

const DisconnectButton = styled(Button)`
  background-color: ${$.color.red};
  &:hover {
    border: 2px solid ${$.color.red};
    color: ${$.color.red};
  }
`;

const manualSettings = { ...defaultSettings, port: 5901 };

const ManualVM = () => {
  const textRef = useRef(null);
  const [noVNCInstance, setNoVNCInstance] = useState(null);
  const [vncOptions, setVNCOptions] = useState(manualSettings);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams && searchParams.get('ip')) {
        setVNCOptions(prev => {
          return {
            ...prev,
            host: searchParams.get('ip'),
          };
        });
      }
    }
  }, []);

  return (
    <>
      <Toolbar>
        <TextInput
          ref={textRef}
          defaultValue={vncOptions.host || ''}
          placeholder="IP Address"
        />
        <ConnectButton
          onClick={() => {
            const ipAddress = textRef.current.value;

            if (
              ipAddress !== '' &&
              ipAddress.split('.').length >= 4 &&
              noVNCInstance
            ) {
              setVNCOptions({ ...vncOptions, host: ipAddress });
              noVNCInstance.swap({ ...vncOptions, host: ipAddress });
            }
          }}
        >
          Connect
        </ConnectButton>
        <DisconnectButton
          onClick={() => {
            if (noVNCInstance) {
              noVNCInstance.disconnect();
            }
          }}
        >
          Disconnect
        </DisconnectButton>
      </Toolbar>
      <VMContainer>
        <LoadableVNC
          options={vncOptions}
          noVNCInstance={noVNCInstance}
          setNoVNCInstance={setNoVNCInstance}
        />
      </VMContainer>
    </>
  );
};

export default ManualVM;
